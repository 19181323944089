import type { FC } from 'react';
import { useCallback } from 'react';
import { Card, CardHeader, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { ArtifactDetailTags} from './artifact-tags';
import { ArtifactModel } from 'src/models/artifact';
import { PropertyList } from 'src/shared/components/property-list';
import { PropertyListItem } from 'src/shared/components/property-list-item';
import { formatAsDate } from 'src/shared/utils/date-utils';
import { ArtifactImage } from 'src/models/artifact-file';


interface ArtifactImageViewerProps {
    artifact: ArtifactModel;
    artifactImage?: ArtifactImage;
    onClose?: () => void;
    open?: boolean;
}

export const ArtifactImageViewer: FC<ArtifactImageViewerProps> = ({ artifact, artifactImage, onClose, open = false }) => {
    const handleClose = useCallback((event: string, reason: string): void => {
        if (reason !== 'backdropClick' && reason !== 'backdropClick') {
            onClose?.();
        }
    },
        [onClose]
    );


    return (

        <Dialog
            fullWidth
            maxWidth={artifactImage?.model?.width && artifactImage.model.width > 1000 ? 'xl' : 'lg'}
            open={open && !!artifactImage}
            onClose={handleClose}
        >
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: 100 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Stack spacing={3}
                sx={{
                    px: 3,
                    py: 1
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">

                    <Typography variant="h5">
                        Artifact Image
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={onClose}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
                <ArtifactDetailTags artifact={artifact} />
            </Stack>

            <DialogContent>
                <Stack spacing={0}>


                    {artifactImage && (<>
                        <Typography align='center'>
                            <h5>{artifactImage.label}</h5>
                        </Typography>

                        <img
                            src={`data:image/png;base64, ${artifactImage.data}`}
                            alt={artifactImage.label}
                            loading="lazy"
                            onContextMenu={(e)=>e.preventDefault()}
                            style={{ borderRadius: '10px', width: artifactImage.model?.width || '200', height: artifactImage.model?.height || '210', cursor: 'pointer', margin: '0 auto', maxWidth: 1000, maxHeight: 1000}}

                        />



                    </>)}
                    {artifactImage && artifactImage.model && (

                        <Card sx={{ mt: 2 }}>
                            <CardHeader title="Image Information" />
                            <Stack spacing={2}>
                                <PropertyList>
                                    <PropertyListItem
                                        divider
                                        label="File  ID"
                                        value={artifactImage.model?.id}
                                    />
                                    <PropertyListItem
                                        divider
                                        label="Dimensions"
                                        value={`${artifactImage.model?.width} W x ${artifactImage.model?.height} H`}
                                    />
                                    <PropertyListItem
                                        divider
                                        label="Resolution"
                                        value={`${artifactImage.model?.resolutionX} X x ${artifactImage.model?.resolutionY} Y`}
                                    />
                                    <PropertyListItem
                                        divider
                                        label="Size"
                                        value={`${artifactImage.model?.sizeBytes} Bytes`}
                                    />
                                    <PropertyListItem
                                        divider
                                        label="Storage Path"
                                        value={`/Artifact_Files/${artifactImage.model?.blobPath}/${artifactImage.model?.blobName}.${artifactImage.model?.blobExtension}`}
                                    />

                                    <PropertyListItem
                                        divider
                                        label="Date Created / Imported"
                                        value={formatAsDate(artifactImage.model?.createdUtc)}
                                    />


                                </PropertyList>
                            </Stack>
                        </Card>
                    )}

                </Stack>

            </DialogContent>
        </Dialog>

    );
};

