import { splitCamelCase } from "src/shared/utils/split-camel-case";

export interface ArtifactTransferModel {
    id: number;
    artifactId: number;
    batch?: number;
    curatorDispositionId?: number;
    artifactLocationId?: number;
    binId: number;
    createdByUserId?: number;
    createdByUserImport?: string;
    createdUtc: Date;
}

export interface ArtifactTransferRow extends ArtifactTransferModel {
    curatorDispositionName?: string;
    artifactLocationName?: string;
    binName?: string;
    createdByUserName?: string;
}

export enum ArtifactTransferType {
    LegacyLocation = 10,
    InventoryLocation = 20
}

export const artifactTransferTypes = Object.keys(ArtifactTransferType)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
        value: ArtifactTransferType[key as keyof typeof ArtifactTransferType],
        label: splitCamelCase(key),
    }));