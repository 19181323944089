import { Chip, IconButton, Skeleton, SvgIcon } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { RouterLink } from "src/shared/components/router-link";
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatAsDate } from "../utils/date-utils";


export const idColumn = (pageSize: number, field: string, flex: number = .10): GridColDef => {
    return            {
        field,
        headerName: 'ID',
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        renderCell: (params) => params.value >= Number.MAX_SAFE_INTEGER - pageSize ? <Skeleton animation="wave" variant="text" /> : params.value,
    };
};


export const textColumn = (field: string, headerName: string, flex: number = .20): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : params.value,
    };
};
export const delimitedTextColumn = (field: string, headerName: string, splitAfter=">", flex: number = .20): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        renderCell: (params: any) => {
            
            if(params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ) {
                return <Skeleton animation="wave" variant="text" />;
            }else {
                const value = params.value as string ?? undefined;
                if(!value) {
                    return "";
                }
                const values = value.split(splitAfter);
                return <>
                    {values.map(m =>  <>{m}<br/></>)}
                </>;
            
            }
           
        },
    };
};


export const numericColumn = (field: string, headerName: string, flex: number = .20): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : params.value?.toLocaleString(),
    };
};


export const renderColumn = (field: string, headerName: string, render: (params: any) => any, flex: number = .20): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : render(params),
    };
};

export const imageDataColumn = (field: string, headerName: string, maxHeight = 100, flex: number = .15): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        sortable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : <img src={`data:image/png;base64, ${params.value}`} height={maxHeight}/>,
    };
};

export const dateColumn = (field: string, headerName: string, flex: number = .20): GridColDef => {

    return       {
        field,
        headerName,
        flex,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : formatAsDate(params.value),
    };
};


export const yesNoColumn = (field: string, headerName: string, flex: number = .10): GridColDef => {
    return       {
        field,
        headerName,
        flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: (params: any) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : params.value ? "Yes" : "No",
    };
};


export const activeInactiveColumn = (field: string, headerName: string, flex: number = .10): GridColDef => {
    return            {
        field,
        headerName,
        flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: (params) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : params.value ?  <Chip size="small" label="Active" color="success" variant="outlined"/> :  <Chip size="small" label="Inactive" color="warning" variant="outlined" />,
    };
};

export const editActionColumn = (editButtonAccessible: boolean, href: (rowId: any) => string, flex: number = .10): GridColDef => {
    return       {
        field: 'actions',
        headerName: 'Actions',
        flex: flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: (params) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : (
        <IconButton
            component={RouterLink}
            href={href( params.row?.id)}
            disabled={!editButtonAccessible}
        >
            <SvgIcon>
                <Edit02Icon />
            </SvgIcon>
        </IconButton>
        ),
    }
};

export const deleteActionColumn = (buttonAccessible: boolean, onClick: (rowId: any) => Promise<void>, flex: number = .10): GridColDef => {
    return       {
        field: 'actions',
        headerName: 'Actions',
        flex: flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: (params) => params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : (
        <IconButton
            onClick={(e) => {
                onClick(params.row?.id);
                e.stopPropagation();
            }}
            disabled={!buttonAccessible}
        >
            <SvgIcon>
                <DeleteIcon />
            </SvgIcon>
        </IconButton>
        ),
    }
};

export const viewActionColumn = (viewAction?: (rowId: any) => string, flex: number = .10): GridColDef => {
    return       {
        field: 'actions',
        headerName: 'Actions',
        flex: flex,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        sortable: false,
        hideable: false,
        renderCell: (params) =>  params.row?.id >= Number.MAX_SAFE_INTEGER - 100 ? <Skeleton animation="wave" variant="text" /> : (
        <>
        {viewAction && params.row?.id && (
            <IconButton
            component={RouterLink}
            href={viewAction(params.row?.id)}
            >
                <SvgIcon>
                    <ArrowRightIcon />
                </SvgIcon>
            </IconButton>
        )}
 
        </>
        ),
    }
};