import { FormControl, MenuItem, TextField } from "@mui/material";
import { ChangeEvent, createRef, forwardRef, RefObject, useEffect, useImperativeHandle, useState } from "react";
import { FormFieldDefinition, FormFieldHandle } from "./form-field";


export interface FormSelectItem<T> {
    label: string;
    value: T;
}

interface FormSelectProps {
    label: string;
    name: string;
    initialValue?: any | null;
    datasource: any[];
    onChange: (key: string, value: any | null) => void;
    disabled?: boolean;
}

export interface FormSelectHandle extends FormFieldHandle {
    getValue: () => any;
    setValue: (value: any) => void;
};





export enum FormSelectItemLabel
{
    All,
    None
}

export const toFormSelectItems = (rows: { name: string, id: number }[], labelType: FormSelectItemLabel): FormSelectItem<number>[] => {
    const items = rows.map<FormSelectItem<number>>(r => ({ label: r.name, value: r.id })) || [];
    items.unshift({ value: -1, label: labelType === FormSelectItemLabel.All ? "All" : "< None >" })
    return items;
}

export const FormSelect = forwardRef<FormSelectHandle, FormSelectProps>(({ label, name, initialValue, datasource, onChange, disabled = false }, ref) => {

    const [fieldValue, setFieldValue] = useState<any>(initialValue);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(disabled);

    useImperativeHandle(ref, () => ({
        getValue() {
            return fieldValue;
        },
        setValue(value: any) {
            changeFieldValue(value);
        },
        resetValue() {
            changeFieldValue(initialValue);
        },
        isDisabled() {
            return fieldDisabled;
        },
        setDisabled(value: boolean) {
            setFieldDisabled(value);
        }
    }));

    const handleFieldChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        changeFieldValue(value);
    }

    const changeFieldValue = (value: any) => {
        setFieldValue(value);
        onChange(name, value);
    }

    useEffect(()=>{
        setFieldDisabled(disabled);
    },[disabled]);

    return (
        <FormControl >
            <TextField
                select
                name={name}
                value={fieldValue}
                onChange={handleFieldChanged}
                label={label}
                disabled={fieldDisabled}
            >
                {datasource.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    )
});


