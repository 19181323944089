import { styled } from '@mui/material/styles';

export const ArtifactGridLayout = styled(
    'div',
    { shouldForwardProp: (prop) => prop !== 'open' }
)<{ open?: boolean; }>(
    ({ theme, open }) => ({
        flexGrow: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        zIndex: 1,
        // [theme.breakpoints.up('lg')]: {
        //   marginLeft: -380
        // },
        marginLeft: -300 + 24,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        ...(open && {
            // [theme.breakpoints.up('lg')]: {
            //   marginLeft: 0
            // },
            marginLeft: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        })
    })
);