
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { textColumn, yesNoColumn, idColumn, dateColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useLocation, useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';
import { ArtifactRow, ArtifactTagSearchMode, artifactTagSearchModes } from 'src/models/artifact';
import { GetArtifactsQuery, GetArtifactsQueryResult } from 'src/api/queries/artifact/get-artifacts';
import ArtifactSearchPanel, { ArtifactSearchPanelDatasources, ArtifactSearchPanelFields } from './components/artifact-search-panel';
import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { ArtifactDrawer } from './components/artifact-drawer';
import { useDialog } from 'src/shared/hooks/use-dialog';
import { RouterLink } from 'src/shared/components/router-link';
import { GetWreckSitesQuery, GetWreckSitesQueryResult } from 'src/api/queries/wreck-site/get-wreck-sites';
import { GetVesselsQuery, GetVesselsQueryResult } from 'src/api/queries/vessel/get-vessels';
import { ArtifactGridLayout } from './components/artifact-grid-layout';
import { getLookupSelectFieldItems, LookupSelectFieldItemLabel } from 'src/shared/utils/get-lookup-select-items';


const ArtifactSearchPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const addButtonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsCreate) || false;
    const latLonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsExportLatLon) || false;
    const loadingSize = 10;
    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
            }
        });
    })(loadingSize);


    const columns: GridColDef[] = [
        idColumn(loadingSize, nameof<ArtifactRow>("id")),
        textColumn(nameof<ArtifactRow>("tag"), "Tag"),
        textColumn(nameof<ArtifactRow>("artifactDescriptionName"), "Description"),
        textColumn(nameof<ArtifactRow>("artifactLocationName"), "Location"),
        textColumn(nameof<ArtifactRow>("artifactStatusName"), "Status"),
        textColumn(nameof<ArtifactRow>("artifactTypeName"), "Type"),
        textColumn(nameof<ArtifactRow>("artifactClassName"), "Class"),
        textColumn(nameof<ArtifactRow>("conservationBatch"), "Batch"),
        textColumn(nameof<ArtifactRow>("dispositionName"), "Disposition"),
        textColumn(nameof<ArtifactRow>("hole"), "Hole"),
        ...(latLonAccessible ? [
            textColumn(nameof<ArtifactRow>("latitude"), "Latitude"),
            textColumn(nameof<ArtifactRow>("longitude"), "Longitude"),
            textColumn(nameof<ArtifactRow>("latitudeDecimalDegrees"), "Latitude DD"),
            textColumn(nameof<ArtifactRow>("longitudeDecimalDegrees"), "Longitude DD"),
        ] : []),
        textColumn(nameof<ArtifactRow>("operationName"), "Operation"),
        textColumn(nameof<ArtifactRow>("ownerName"), "Owner"),
        textColumn(nameof<ArtifactRow>("points"), "Points"),
        textColumn(nameof<ArtifactRow>("quantity"), "Quantity"),
        dateColumn(nameof<ArtifactRow>("recoveryDate"), "Recovered"),
        textColumn(nameof<ArtifactRow>("securitySticker"), "Security Sticker"),
        textColumn(nameof<ArtifactRow>("value"), "Value"),
        textColumn(nameof<ArtifactRow>("vesselName"), "Vessel"),
        textColumn(nameof<ArtifactRow>("year"), "Year"),
        textColumn(nameof<ArtifactRow>("wreckSiteName"), "Wreck Site"),
        yesNoColumn(nameof<ArtifactRow>("divisible"), "Divisible"),
        yesNoColumn(nameof<ArtifactRow>("highTicket"), "High Ticket"),
        yesNoColumn(nameof<ArtifactRow>("investor"), "Investor"),
        dateColumn(nameof<ArtifactRow>("lastModifiedDate"), "Last Modified"),
        textColumn(nameof<ArtifactRow>("lastModifiedBy"), "Last Modified By"),
    ];
    const [rows, setRows] = useState<any[]>(loadingRows);
    const [total, setTotal] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        // Default hidden columns
        [nameof<ArtifactRow>("artifactClassName")]: false,
        [nameof<ArtifactRow>("artifactDescriptionName")]: true,
        [nameof<ArtifactRow>("artifactLocationName")]: false,
        [nameof<ArtifactRow>("artifactStatusName")]: false,
        [nameof<ArtifactRow>("artifactTypeName")]: false,
        [nameof<ArtifactRow>("conservationBatch")]: false,
        [nameof<ArtifactRow>("divisible")]: false,
        [nameof<ArtifactRow>("dispositionName")]: false,
        [nameof<ArtifactRow>("hole")]: false,
        [nameof<ArtifactRow>("highTicket")]: false,
        [nameof<ArtifactRow>("id")]: true,
        [nameof<ArtifactRow>("investor")]: false,
        [nameof<ArtifactRow>("lastModifiedDate")]: true,
        [nameof<ArtifactRow>("lastModifiedBy")]: false,
        [nameof<ArtifactRow>("latitude")]: false,
        [nameof<ArtifactRow>("longitude")]: false,
        [nameof<ArtifactRow>("latitudeDecimalDegrees")]: false,
        [nameof<ArtifactRow>("longitudeDecimalDegrees")]: false,
        [nameof<ArtifactRow>("operationName")]: false,
        [nameof<ArtifactRow>("ownerName")]: false,
        [nameof<ArtifactRow>("points")]: true,
        [nameof<ArtifactRow>("quantity")]: false,
        [nameof<ArtifactRow>("recoveryDate")]: true,
        [nameof<ArtifactRow>("securitySticker")]: false,
        [nameof<ArtifactRow>("tag")]: true,
        [nameof<ArtifactRow>("value")]: false,
        [nameof<ArtifactRow>("vesselName")]: false,
        [nameof<ArtifactRow>("year")]: false,
        [nameof<ArtifactRow>("wreckSiteName")]: true
    });
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [searchPanelOpen, setSearchPanelOpen] = useState<boolean>(true);
    const [selectedArtifactId, setSelectedArtifactId] = useState<number>();
    const [artifactSearchPanelDatasources, setArtifactSearchPanelDatasources] = useState<ArtifactSearchPanelDatasources>();

    const dialog = useDialog<number>();

    const handleArtifactSelect = (artifactId: string): void => {
        navigate('#' + artifactId);
    };

    const handleArtifactDrawerClose = (): void => {
        navigate("#");
    }

    const handleSearchPanelSubmit = async (fields: ArtifactSearchPanelFields) => {
        setRows(loadingRows);
        setLoading(true);
        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            ...fields
        });
        setRows(result.rows);
        setTotal(result.total);
        setLoading(false);
    };

    const handleSearchPanelReset = async (fields: ArtifactSearchPanelFields) => {
        setLoading(true);

        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            tagSearchMode: ArtifactTagSearchMode.ExactMatch,
        });
        setRows(result.rows);
        setTotal(result.total);
        setLoading(false);
    };

    useEffect(() => {
        const artifactId = + ((hash || "").replace("#", "").trim());
        if (artifactId > 0) {
            setSelectedArtifactId(artifactId)
            dialog.handleOpen(artifactId);
        } else {
            setSelectedArtifactId(undefined)
            dialog.handleClose();
        }

        // Listen for hash change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])


    useMount(() => {

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsRead)) {
            navigate(paths.error403);
            return;
        }
        
        const loadData = async () => {

            try {
                const artifactDescriptions = await getLookupSelectFieldItems(ApiQuery.GetArtifactDescriptions, LookupSelectFieldItemLabel.All);
                const artifactTypes = await getLookupSelectFieldItems(ApiQuery.GetArtifactTypes, LookupSelectFieldItemLabel.All);
                const operations = await getLookupSelectFieldItems(ApiQuery.GetOperations, LookupSelectFieldItemLabel.All);
                const wreckSites = (await Api.executeQuery<GetWreckSitesQuery, GetWreckSitesQueryResult>(ApiQuery.GetWreckSites, {}))?.rows;
                const vessels = (await Api.executeQuery<GetVesselsQuery, GetVesselsQueryResult>(ApiQuery.GetVessels, {}))?.rows;


                setArtifactSearchPanelDatasources(
                    {
                        artifactDescriptions,
                        artifactTypes,
                        operations,
                        wreckSites,
                        vessels,
                        artifactTagSearchModes
                    }
                )
                const defaultOperationId = auth?.authState?.principal?.defaultOperationId;

                const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
                    ApiQuery.GetArtifacts, {
                    tagSearchMode: ArtifactTagSearchMode.ExactMatch,
                    operationId: defaultOperationId || undefined
                });
                setRows(result.rows);
                setTotal(result.total);
                setLoading(false);
            } catch (err) {
                setErrors(err.response.data.errors || [err.response.statusText])
                setLoading(false);
            }
        }

        loadData();
    });

    return (


        <Box
            component="main"
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
                position: 'relative',

            }}
        >
            <Box
                ref={rootRef}
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    left: 0,
                    bottom: 0
                }}
            >


                <ArtifactSearchPanel
                    datasources={artifactSearchPanelDatasources}
                    defaultOperationId={auth?.authState?.principal?.defaultOperationId}
                    container={rootRef.current}
                    open={searchPanelOpen}
                    onOpenChanged={setSearchPanelOpen}
                    onSubmit={handleSearchPanelSubmit}
                    onReset={handleSearchPanelReset}
                />



                <ArtifactGridLayout open={searchPanelOpen}>
                    <Stack spacing={4}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >

                                <Typography variant="h4">
                                    Artifacts
                                </Typography>

                            </Stack>

                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >

                                <Button
                                    startIcon={(
                                        <SvgIcon>
                                            <PlusIcon />
                                        </SvgIcon>
                                    )}
                                    variant="contained"
                                    disabled={!addButtonAccessible}
                                    component={RouterLink}
                                    href={`/artifacts/create`}
                                >
                                    Add
                                </Button>
                            </Stack>
                        </Stack>


                        {!loading && (
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0}
                            >
                                <Typography variant="body1" sx={{size:10}}>
                                    {total > 250 ? (
                                        <> Displaying the most recent 250 out of {total.toLocaleString()} search results.</>
                                    ) : total === 0 ? (

                                        <> Displaying 0 search results.</>
                                    ) : (
                                        <> Displaying all {total.toLocaleString()} search results.</>
                                    )}
                                </Typography>
                            </Stack>
                        )}

                        <UIMessage
                            type="error"
                            messages={errors}
                            onClose={() => setErrors([])} />
                        <UIDataGrid
                            loading={loading}
                            hideFooterPagination={loading}
                            rows={rows}
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            disableColumnSelector={false}
                            onRowClick={(e) => handleArtifactSelect(e.row['id'])}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { disableToolbarButton: false },
                                    printOptions: { disableToolbarButton: true },
                                },
                            }}
                            rowPointer={true}
                            autoHeight
                        />
                    </Stack>

                </ArtifactGridLayout>

                <ArtifactDrawer
                    container={rootRef.current}
                    onClose={() => handleArtifactDrawerClose()}
                    open={dialog.open}
                    artifactId={selectedArtifactId}
                />
            </Box>

        </Box>

    );
};

export default ArtifactSearchPage;

