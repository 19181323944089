import { useState, type FC, ChangeEvent } from 'react';

import type { Theme } from '@mui/material';
import {
  Stack,
  useMediaQuery,
  Unstable_Grid2 as Grid,
  Card,
  CardHeader,
  Tab,
  Tabs,
  Button,
  SvgIcon
} from '@mui/material';
import { PropertyList } from 'src/shared/components/property-list';
import { PropertyListItem } from 'src/shared/components/property-list-item';
import { coinDescriptionIds, emeraldDescriptionIds, pearlDescriptionIds, silverIngotDescriptionIds } from 'src/models/artifact-description';
import { formatAsDate } from 'src/shared/utils/date-utils';
import ArtifactImageList from './artifact-image-list';
import { ArtifactImage } from 'src/models/artifact-file';
import { ArtifactTransferRow } from 'src/models/artifact-transfer';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { dateColumn, delimitedTextColumn, renderColumn, textColumn, yesNoColumn } from 'src/shared/components/grid-columns';
import { GridColDef } from '@mui/x-data-grid';
import { nameof } from 'src/shared/utils/nameof';
import { ArtifactCertificateModel, ArtifactCertificateRow } from 'src/models/artifact-certificate';
import { useMount } from 'src/shared/hooks/use-mount';
import PrintIcon from '@mui/icons-material/Print';
import { useDialog } from 'src/shared/hooks/use-dialog';
import { ArtifactCertificateIssuer } from './artifact-certificate-issuer';
import { ArtifactModel } from 'src/models/artifact';



type ArtifactLayoutType = 'curator' | 'conservator' | 'coin';

interface ArtifactViewProps {
  onEdit?: () => void;
  artifact: ArtifactModel;
  images: ArtifactImage[];
  transfers: ArtifactTransferRow[];
  certificates: ArtifactCertificateRow[];
  onCertificateIssued: (certificate: ArtifactCertificateModel) => void;
  issueCertificatesEnabled: boolean;
}

export const ArtifactView: FC<ArtifactViewProps> = ({ artifact, transfers: artifactTransfers, certificates: artifactCertificates, onCertificateIssued: onArtifactCertificateIssued, images: artifactImages, issueCertificatesEnabled: issueArtifactCertificatesEnabled }) => {
  const [currentTab, setCurrentTab] = useState<ArtifactLayoutType>('curator');
  const [pearlSectionVisible, setPearlSectionVisible] = useState<boolean>(false);
  const [coinSectionVisible, setCoinSectionVisible] = useState<boolean>(false);
  const [emeraldSectionVisible, setEmeraldSectionVisible] = useState<boolean>(false);
  const [silverIngotSectionVisible, setSilverIngotSectionVisible] = useState<boolean>(false);


  const tabs: { label: string, value: ArtifactLayoutType }[] = [
    { label: 'Curator Details', value: 'curator' },
    { label: 'Conservator Details', value: 'conservator' },
    { label: 'Coin Details', value: 'coin' }
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value as ArtifactLayoutType);
  };


  const setSectionVisiblity = (artifactDescriptionId: number = 0) => {
    setPearlSectionVisible(pearlDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setCoinSectionVisible(coinDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setEmeraldSectionVisible(emeraldDescriptionIds.indexOf(artifactDescriptionId) > -1);
    setSilverIngotSectionVisible(silverIngotDescriptionIds.indexOf(artifactDescriptionId) > -1);
  };

  useMount(() => {
    setSectionVisiblity(artifact.artifactDescriptionId);
  });

  return (
    <>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        sx={{ mt: 1 }}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {/* <Tab
          key={'label'}
          label="Layout:"
          value={'label'}
          disabled
        /> */}
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>


      <Stack spacing={3}>

        <Grid
          container
          spacing={4}
        >
          <Grid
            xs={12}
            lg={8}
          >
            {currentTab === 'conservator' ? (
              <ConservatorArtifactLayout 
                artifact={artifact} 
                artifactImages={artifactImages}  
                transfers={artifactTransfers} 
                certificates={artifactCertificates} 
                onCertificateIssued={onArtifactCertificateIssued}
                issueCertificatesEnabled={issueArtifactCertificatesEnabled}/>
            ) : currentTab === 'curator' ? (
              <CuratorArtifactLayout
                artifact={artifact}
                artifactImages={artifactImages}  
                transfers={artifactTransfers}
                certificates={artifactCertificates}
                pearlSectionVisible={pearlSectionVisible}
                emeraldSectionVisible={emeraldSectionVisible}
                coinSectionVisible={coinSectionVisible}
                silverIngotSectionVisible={silverIngotSectionVisible}
                onCertificateIssued={onArtifactCertificateIssued}
                issueCertificatesEnabled={issueArtifactCertificatesEnabled}/>
            ) : (
              <CoinArtifactLayout 
                artifact={artifact}
                artifactImages={artifactImages}  
                transfers={artifactTransfers} 
                certificates={artifactCertificates} 
                onCertificateIssued={onArtifactCertificateIssued}
                issueCertificatesEnabled={issueArtifactCertificatesEnabled}/>
            )}

          </Grid>
          <Grid
            xs={12}
            lg={4}
          >
            <ArtifactImageList artifact={artifact} images={artifactImages} />

          </Grid>
        </Grid>
      </Stack>

    </>
  );
};

interface ArtifactLayoutProps {
  artifact: ArtifactModel; 
  artifactImages: ArtifactImage[];
  transfers: ArtifactTransferRow[];
  certificates: ArtifactCertificateRow[];
  onCertificateIssued: (certificate: ArtifactCertificateModel) => void;
  issueCertificatesEnabled: boolean;
}

interface ConservatorArtifactLayoutProps extends ArtifactLayoutProps {
}

const ConservatorArtifactLayout: FC<ConservatorArtifactLayoutProps> = ({ artifact, artifactImages, transfers, certificates, onCertificateIssued, issueCertificatesEnabled }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';
  const formatPrePostString = (pre?: string, post?: string, unit?: string) => {
    return (!pre || pre.length === 0 ? "See Photo" : pre + ' ' + unit) + " / " + (!post || post.length === 0 ? "See Photo" : post + ' ' + unit);
  }

  const formatPrePostWeightString = (pre?: string, post?: string, unit?: string) => {
    return (!pre || pre.length === 0 ? "None" : pre + ' ' + unit) + " / " + (!post || post.length === 0 ? "None" : post + ' ' + unit);
  }
  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader title="Overview" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Tag #"
              value={artifact.tag}
            />
            <PropertyListItem
              align={align}
              divider
              label="Operation"
              value={artifact.operationName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date Found"
              value={formatAsDate(artifact.recoveryDate)}
            />
            <PropertyListItem
              align={align}
              divider
              label="Vessel"
              value={artifact.vesselName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Wreck Site"
              value={artifact.wreckSiteName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Quantity"
              value={artifact.quantity}
            />
            <PropertyListItem
              align={align}
              divider
              label="Description"
              value={artifact.artifactDescriptionName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Type"
              value={artifact.artifactTypeName}
            />

            <PropertyListItem
              align={align}
              divider
              label="Date Checked into Lab"
              value={formatAsDate(artifact.inDate)}
            />
            <PropertyListItem
              align={align}
              divider
              label="Conservator"
              value={artifact.conservatorName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Protocol"
              value={artifact.protocolName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Status"
              value={artifact.artifactStatusName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Disposition"
              value={artifact.dispositionName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Concretion Removal"
              value={artifact.concretionRemovalName}
            />
          </PropertyList>
        </Stack>
      </Card>

      <Card>
        <CardHeader title="DGPS Recovery Coordinates" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Latitude"
              value={artifact.latitude}
            />
            <PropertyListItem
              align={align}
              divider
              label="Latitude (DD)"
              value={artifact.latitudeDecimalDegrees}
            />
            <PropertyListItem
              align={align}
              divider
              label="Longitude"
              value={artifact.longitude}
            />
            <PropertyListItem
              align={align}
              divider
              label="Longitude (DD)"
              value={artifact.longitudeDecimalDegrees}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card>
        <CardHeader title="Measurements (Pre/Post Conservation)" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Height"
              value={formatPrePostString(artifact.preHeightCm, artifact.postHeightCm, 'cm')}
            />
            <PropertyListItem
              align={align}
              divider
              label="Width"
              value={formatPrePostString(artifact.preWidthCm, artifact.postWidthCm, 'cm')}
            />
            <PropertyListItem
              align={align}
              divider
              label="Length"
              value={formatPrePostString(artifact.preLengthCm, artifact.postLengthCm, 'cm')}
            />
            <PropertyListItem
              align={align}
              divider
              label="Weight"
              value={formatPrePostWeightString(artifact.preWeightGm, artifact.postWeightGm, 'gm')}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card>
        <CardHeader title="Chloride Removal Process" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Method"
              value={artifact.chlorideRemovalName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Release Test"
              value={artifact.chlorideReleaseTestName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date Start"
              value={formatAsDate(artifact.clInDate)}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date End"
              value={formatAsDate(artifact.clOutDate)}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card>
        <CardHeader title="Chemical Stabilization Process" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Method"
              value={artifact.chemicalStabilizationName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date Start"
              value={formatAsDate(artifact.chemInDate)}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date End"
              value={formatAsDate(artifact.chemOutDate)}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card>
        <CardHeader title="Lab Disposition" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Results"
              value={artifact.conservationResultName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Disposition"
              value={artifact.curatorDispositionName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Date"
              value={formatAsDate(artifact.curatorDispositionDate)}
            />
          </PropertyList>
        </Stack>
      </Card>
      <ArtifactAdminInformationCard artifact={artifact} />
      <ArtifactTransferInformationCard transfers={transfers} />
      <ArtifactCertificateInformationCard 
        artifact={artifact} 
        artifactImages={artifactImages} 
        certificates={certificates} 
        onCertificateIssued={onCertificateIssued}
        issueCertificatesEnabled={issueCertificatesEnabled}  />
      <ArtifactCommentsCard artifact={artifact} />
    </Stack>
  );

}

interface CuratorArtifactLayoutProps extends ArtifactLayoutProps {
  coinSectionVisible: boolean;
  silverIngotSectionVisible: boolean;
  emeraldSectionVisible: boolean; 
  pearlSectionVisible: boolean;
}


const CuratorArtifactLayout: FC<CuratorArtifactLayoutProps> = ({ artifact, artifactImages, transfers, certificates, onCertificateIssued, issueCertificatesEnabled, coinSectionVisible, silverIngotSectionVisible, emeraldSectionVisible, pearlSectionVisible }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';

  return (
    <Stack spacing={4}>
      <ArtifactOverviewCard artifact={artifact} />

      <Card hidden={!coinSectionVisible}>
        <CardHeader title="Coin Details" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Coin #"
              value={artifact.coinNumber}
            />
            <PropertyListItem
              align={align}
              divider
              label="DenominationName"
              value={artifact.denominationName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Mint"
              value={artifact.mintName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Reign"
              value={artifact.reignName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Assayer"
              value={!artifact.assayerName ? "" : artifact.assayerName + " (" + (artifact.assayerCertainty ? 'certain' : 'uncertain') + ")"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Coin Date"
              value={artifact.coinDate || "Not visible"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Grade"
              value={artifact.gradeName}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card hidden={!silverIngotSectionVisible}>
        <CardHeader title="Silver Ingot Details" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Manifest"
              value={artifact.manifest}
            />
            <PropertyListItem
              align={align}
              divider
              label="Class Factor"
              value={artifact.classFactor}
            />
            <PropertyListItem
              align={align}
              divider
              label="Measurements"
              value={artifact.measurements}
            />
            <PropertyListItem
              align={align}
              divider
              label="Karats"
              value={artifact.karats}
            />
            <PropertyListItem
              align={align}
              divider
              label="Troy Lb"
              value={artifact.weightTroyPounds}
            />
            <PropertyListItem
              align={align}
              divider
              label="Troy Oz"
              value={artifact.weightTroyOunces}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card hidden={!emeraldSectionVisible} >
        <CardHeader title="Emerald Details" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Class"
              value={artifact.artifactClassName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Carat"
              value={artifact.carat}
            />
          </PropertyList>
        </Stack>
      </Card>
      <Card hidden={!pearlSectionVisible} >
        <CardHeader title="Pearl Details" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Grade"
              value={artifact.pearlGradeName}
            />

          </PropertyList>
        </Stack>
      </Card>
      <ArtifactAdminInformationCard artifact={artifact} />
      <Card>
        <CardHeader title="Other Information" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="TSI #"
              value={artifact.tsiNumber}
            />
            <PropertyListItem
              align={align}
              divider
              label="Flipit 1"
              value={artifact.flippit1}
            />
            <PropertyListItem
              align={align}
              divider
              label="Flipit 2"
              value={artifact.flippit2}
            />
            <PropertyListItem
              align={align}
              divider
              label="Print Batch Date"
              value={formatAsDate(artifact.certificatePrintedDate)}
            />
          </PropertyList>
        </Stack>
      </Card>
      <ArtifactTransferInformationCard transfers={transfers} />
      <ArtifactCertificateInformationCard 
        artifact={artifact} 
        artifactImages={artifactImages} 
        certificates={certificates} 
        onCertificateIssued={onCertificateIssued}
        issueCertificatesEnabled={issueCertificatesEnabled}  />
      <ArtifactCommentsCard artifact={artifact} />
    </Stack>
  );

}

interface CoinArtifactLayoutProps extends ArtifactLayoutProps {
}

const CoinArtifactLayout: FC<CoinArtifactLayoutProps> = ({ artifact, artifactImages, transfers, certificates, onCertificateIssued, issueCertificatesEnabled }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';

  return (
    <Stack spacing={4}>
      <ArtifactOverviewCard artifact={artifact} />

      <Card>
        <CardHeader title="Coin Details" />
        <Stack spacing={2}>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Coin #"
              value={artifact.coinNumber}
            />
            <PropertyListItem
              align={align}
              divider
              label="DenominationName"
              value={artifact.denominationName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Mint"
              value={artifact.mintName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Reign"
              value={artifact.reignName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Assayer"
              value={artifact.assayerName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Assayer Certanity"
              value={artifact.assayerCertainty === true ? 'certain' : artifact.assayerCertainty === false ? 'uncertain' : ''}
            />
            <PropertyListItem
              align={align}
              divider
              label="Coin Date"
              value={artifact.coinDate || "Not visible"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Grade"
              value={artifact.gradeName}
            />
            <PropertyListItem
              align={align}
              divider
              label="Points"
              value={artifact.points}
            />
            <PropertyListItem
              align={align}
              divider
              label="Flipet 1"
              value={artifact.flippit1}
            />
            <PropertyListItem
              align={align}
              divider
              label="Flipit 2"
              value={artifact.flippit2}
            />
            <PropertyListItem
              align={align}
              divider
              label="Print Batch Date"
              value={formatAsDate(artifact.certificatePrintedDate)}
            />
            <PropertyListItem
              align={align}
              divider
              label="Investor"
              value={artifact.investor}
            />
            <PropertyListItem
              align={align}
              divider
              label="Owner Code"
              value={artifact.ownerName}
            />
            <PropertyListItem
              align={align}
              divider
              label="High Ticket"
              value={artifact.highTicket ? "Yes" : "No"}
            />
          </PropertyList>
        </Stack>
      </Card>

      <ArtifactTransferInformationCard transfers={transfers} />
      <ArtifactCertificateInformationCard 
        artifact={artifact} 
        artifactImages={artifactImages} 
        certificates={certificates} 
        onCertificateIssued={onCertificateIssued}
        issueCertificatesEnabled={issueCertificatesEnabled}  />

      <ArtifactCommentsCard artifact={artifact} />
    </Stack>
  );

}

const ArtifactOverviewCard: FC<{ artifact: ArtifactModel }> = (props) => {
  const { artifact } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';
  return (
    <Card>
      <CardHeader title="Overview" />
      <Stack spacing={2}>
        <PropertyList>
          <PropertyListItem
            align={align}
            divider
            label="Tag #"
            value={artifact.tag}
          />
          <PropertyListItem
            align={align}
            divider
            label="Operation"
            value={artifact.operationName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Date Found"
            value={formatAsDate(artifact.recoveryDate)}
          />
          <PropertyListItem
            align={align}
            divider
            label="Security #"
            value={artifact.securitySticker !== '0' ? artifact.securitySticker : ''}
          />
          <PropertyListItem
            align={align}
            divider
            label="Vessel"
            value={artifact.vesselName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Wreck Site"
            value={artifact.wreckSiteName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Quantity"
            value={artifact.quantity}
          />
          <PropertyListItem
            align={align}
            divider
            label="Description"
            value={artifact.artifactDescriptionName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Type"
            value={artifact.artifactTypeName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Status"
            value={artifact.artifactStatusName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Disposition"
            value={artifact.curatorDispositionName}
          />
          <PropertyListItem
            align={align}
            divider
            label="Date of Disposition"
            value={formatAsDate(artifact.curatorDispositionDate)}
          />
          <PropertyListItem
            align={align}
            divider
            label="Post Weight"
            value={artifact.postWeightGm + (!artifact.postWeightGm ? '' : ' gm')}
          />
        </PropertyList>
      </Stack>
    </Card>

  );

}
const ArtifactAdminInformationCard: FC<{ artifact: ArtifactModel }> = (props) => {
  const { artifact } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';

  return (
    <Card>
      <CardHeader title="Admin Information" />
      <Stack spacing={2}>
        <PropertyList>
          <PropertyListItem
            align={align}
            divider
            label="Divisible"
            value={artifact.divisible ? "Yes" : "No"}
          />
          <PropertyListItem
            align={align}
            divider
            label="Points"
            value={artifact.points}
          />
          <PropertyListItem
            align={align}
            divider
            label="Value"
            value={artifact.value}
          />
          <PropertyListItem
            align={align}
            divider
            label="Investor"
            value={artifact.investor}
          />
          <PropertyListItem
            align={align}
            divider
            label="Owner Code"
            value={artifact.ownerName}
          />
          <PropertyListItem
            align={align}
            divider
            label="High Ticket"
            value={artifact.highTicket ? "Yes" : "No"}
          />
        </PropertyList>
      </Stack>
    </Card>
  );

}

const ArtifactCommentsCard: FC<{ artifact: ArtifactModel }> = (props) => {
  const { artifact } = props;
  return (
    <Card>
      <CardHeader title="Comments" />
      <Stack spacing={2}>
        <PropertyList>
          <PropertyListItem
            align='vertical'
            divider
            label="Public Comments"
            value={artifact.comments && artifact.comments.length > 0 ? artifact.comments : 'None'}
          />
          <PropertyListItem
            align='vertical'
            divider
            label="Internal Comments"
            value={artifact.internalComments && artifact.internalComments.length > 0 ? artifact.internalComments : 'None'}
          />

        </PropertyList>
      </Stack>
    </Card>

  );

}

const ArtifactTransferInformationCard: FC<{ transfers: ArtifactTransferRow[] }> = (props) => {
  const { transfers } = props;

  const columns: GridColDef[] = [
    dateColumn(nameof<ArtifactTransferRow>("createdUtc"), "Date"),
    textColumn(nameof<ArtifactTransferRow>("batch"), "Batch", .1),
    textColumn(nameof<ArtifactTransferRow>("artifactLocationName"), "Location", .15),
    textColumn(nameof<ArtifactTransferRow>("curatorDispositionName"), "Disposition", .15),
    delimitedTextColumn(nameof<ArtifactTransferRow>("binName"), "Inventory Location", ">", .20),
    renderColumn("user", "User", (params: any) => params.row.createdByUserName !== null ? params.row.createdByUserName : params.row.createdByUserImport !== null ? params.row.createdByUserImport : ""),
  ];

  return (
    <Card>
      <CardHeader title="Transfer Information" />
      <Stack spacing={2} px={3}>

        <UIDataGrid
          rowHeight={80}
          pageSize={5}
          loading={false}
          rows={transfers}
          columns={columns}
          disableColumnSelector={true}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          rowPointer={true}
          autoHeight
        />

      </Stack>
    </Card>

  );

}


interface ArtifactCertificateInformationCardProps {
   artifact: ArtifactModel;
   artifactImages: ArtifactImage[];
   certificates: ArtifactCertificateRow[]; 
   issueCertificatesEnabled: boolean;
   onCertificateIssued: (certificate: ArtifactCertificateModel) => void;
}

const ArtifactCertificateInformationCard: FC<ArtifactCertificateInformationCardProps> = ({ artifact, artifactImages, certificates, issueCertificatesEnabled, onCertificateIssued }) => {
  const comments = artifact?.certificateComments;
  const certificateIssuerDialog = useDialog();
  const columns: GridColDef[] = [
    dateColumn(nameof<ArtifactCertificateRow>("createdUtc"), "Date"),
    textColumn(nameof<ArtifactCertificateRow>("securitySticker"), "Security Sticker #", .3),
    yesNoColumn(nameof<ArtifactCertificateRow>("isRecertification"), "Recertification", .3),
    renderColumn("user", "User", (params: any) => params.row.createdByUserName !== null ? params.row.createdByUserName : params.row.createdByUserImport !== null ? params.row.createdByUserImport : ""),
  ];



  return (
    <Card>

      <Stack
        alignItems="flex-start"
        direction={{
          xs: 'column',
          md: 'row'
        }}
        justifyContent="space-between"
        spacing={4}
      >

        <CardHeader title="Certificate Information" />
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          py={3}
        >
          <Button
            disabled={!issueCertificatesEnabled}
            onClick={certificateIssuerDialog.handleOpen}
            color="inherit"
            startIcon={(
              <SvgIcon>
                <PrintIcon />
              </SvgIcon>
            )}
          >
            Issue Certificate
          </Button>


        </Stack>
      </Stack>
      <Stack spacing={2}>
        <PropertyList>
          <PropertyListItem
            align='vertical'
            divider
            label="Certificate History"
            value={''}
          >
            <UIDataGrid
             pageSize={5}
              loading={false}
              rows={certificates}
              columns={columns}
              disableColumnSelector={true}
              slotProps={{
                toolbar: {
                  showQuickFilter: false,
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              rowPointer={true}
              autoHeight
            />
          </PropertyListItem>
        </PropertyList>

        <PropertyList>
          <PropertyListItem
            align='vertical'
            divider
            label="Certificate Comments"
            value={comments && comments?.length > 0 ? comments : 'None'}
          />

        </PropertyList>
      </Stack>
      <ArtifactCertificateIssuer
        artifact={artifact}
        artifactImages={artifactImages}
        onClose={certificateIssuerDialog.handleClose}
        open={certificateIssuerDialog.open}
        onCertificateIssued={onCertificateIssued}
      />
    </Card>

  );

}