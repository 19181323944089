
export interface FormFieldHandle {
    isDisabled: () => boolean;
    resetValue: () => void;
    setDisabled: (value: boolean) => void;
}

export class FormFieldDefinition {

    constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    name: string;
    label: string;
}

