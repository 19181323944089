import { Stack, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { FormFieldHandle } from "./form-field";

interface FormDateRangeProps {
    startlabel: string;
    startName: string;
    initialStartValue: Date | null;

    endLabel: string;
    endName: string;
    initialEndValue: Date | null;
    onChange: (key: string, value: Date | null) => void;
    disabled?: boolean;
}

export interface FormDateRangeHandle extends FormFieldHandle {
    getStartValue: () => Date | null;
    setStartValue: (value: Date | null) => void;
    getEndValue: () => Date | null;
    setEndValue: (value: Date | null) => void;
};

export const FormDateRange = forwardRef<FormDateRangeHandle, FormDateRangeProps>(({ startlabel, startName, initialStartValue, endLabel, endName, initialEndValue, onChange, disabled = false }, ref) => {

    const [startValue, setStartValue] = useState<Date | null>(initialStartValue);
    const [endValue, setEndValue] = useState<Date | null>(initialEndValue);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(disabled);


    useImperativeHandle(ref, () => ({
        getStartValue() {
            return startValue;
        },
        setStartValue(value: any) {
            changeStartValue(value);
        },
        getEndValue() {
            return endValue;
        },
        setEndValue(value: any) {
            changeEndValue(value);
        },
        resetValue() {
            changeStartValue(initialStartValue);
            changeEndValue(initialEndValue);
        },
        isDisabled() {
            return fieldDisabled;
        },
        setDisabled(value: boolean) {
            setFieldDisabled(value);
        },
    }));
    
    const dateFormat = "MM/dd/yyyy";
    const changeStartValue = (value: Date | null) => {
        setStartValue(value);
        onChange(startName, value)
    }

    const changeEndValue = (value: Date | null) => {
        setEndValue(value);
        onChange(endName, value)
    }

    

    return (

        <Stack spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    inputFormat={dateFormat}
                    label={startlabel}
                    onChange={changeStartValue}
                    renderInput={(inputProps) => <TextField {...inputProps} />}
                    value={startValue}
                    maxDate={endValue ?? undefined}
                    disabled={fieldDisabled}
                />
                <DatePicker
                    inputFormat={dateFormat}
                    label={endLabel}
                    onChange={changeEndValue}
                    renderInput={(inputProps) => <TextField {...inputProps} />}
                    value={endValue}
                    minDate={startValue ?? undefined}
                    disabled={fieldDisabled}
                />
            </LocalizationProvider>
        </Stack>


    )
});
