import { FormControl, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';
import { FormFieldHandle } from "./form-field";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface FormMultiSelectProps {
    label: string;
    name: string;
    initialValue?: any[];
    datasource: { label: string, value: any}[];
    onChange: (key: string, value: any | null) => void;
    disabled?: boolean;
}

export interface FormMultiSelectHandle extends FormFieldHandle {
    getValue: () => any[] | null;
    setValue: (value: any[] | null) => void;
};

export const FormMultiSelect = forwardRef<FormMultiSelectHandle, FormMultiSelectProps>(({ label, name, datasource, onChange, initialValue = [], disabled = false }, ref) => {
    
    //Autocomplete uses entire datasource objects as values
    const [fieldInternalValue, setFieldInternalValue] = useState<any[]>([]);
    const [firstOptionSelected, setFirstOptionSelected] = useState<boolean>(true);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(disabled);


    useImperativeHandle(ref, () => ({
        getValue() {
            return fieldInternalValue;
        },
        setValue(value: any) {
            changeFieldValue(value);
        },
        resetValue() {
            changeFieldValue(initialValue);
        },
        isDisabled() {
            return fieldDisabled;
        },
        setDisabled(value: boolean) {
            setFieldDisabled(value);
        }
    }));
    
    const handleFieldChanged = (event: any, newValue: any[]) => {
        changeFieldValue(newValue);
    }

    const changeFieldValue = (newValue: any[]) => {
        let filtered = newValue.filter((option: any) => option.value !== -1);
        let ids = [];
        if (filtered?.length > 0) {
            setFieldInternalValue([
                ...filtered,
            ]);
            setFirstOptionSelected(false);
            ids = filtered.map((f: { value: any; }) =>f.value);
        } else {
            setFieldInternalValue([
                ...datasource.slice(0, 1),
            ]);
            setFirstOptionSelected(true);
            ids = [];
        }
        onChange(name, ids); //Publish only values
    }

    useEffect(()=>{
        if(datasource && datasource.length > 0) {
            const items = datasource.filter(d => fieldInternalValue.indexOf(d.value) !== -1);
            if(items.length > 0) {
                setFieldInternalValue(items);
                setFirstOptionSelected(false);
            }else{
                setFieldInternalValue(datasource.slice(0, 1));
                setFirstOptionSelected(true);
            }
        
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasource])

    return (
        <FormControl>
            <Autocomplete
                multiple
                loading={datasource.length === 1}
                id={name}
                value={fieldInternalValue}
                inputMode='none'
                inputValue=''
                onChange={handleFieldChanged}
                options={datasource}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disableCloseOnSelect
                disableClearable={firstOptionSelected}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                        <Checkbox
                            key={option.value}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            value={option.value}
                        />
                        {option.label}
                    </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option.label}
                            {...getTagProps({ index })}
                            {...(option.value !== -1) ? {} : { onDelete: undefined, disabled: true }}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label={label} />
                )}
                limitTags={10}
                disabled={fieldDisabled}
            />
        </FormControl>
    );

});
