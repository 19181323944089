import type { FC } from 'react';
import { useState } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { FormSelect2, FormSelectItemLabel2, FormSelectItem2, toFormSelectItems2 } from 'src/shared/components/form/form-select2';
import UIMessage from 'src/shared/components/ui/ui-message';
import { ArtifactModel } from 'src/models/artifact';
import { nameof } from 'src/shared/utils/nameof';
import { BuildingModel } from 'src/models/building';
import { SafeModel } from 'src/models/safe';
import { ShelfModel } from 'src/models/shelf';
import { BinModel } from 'src/models/bin';
import { LookupRow } from 'src/models/lookup';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { ArtifactTags } from './artifact-tags';
import { FormFieldDefinition } from 'src/shared/components/form/form-field';
import { FormRadioGroup } from 'src/shared/components/form/form-radio-group';
import { ArtifactTransferType, artifactTransferTypes } from 'src/models/artifact-transfer';
import toast from "react-hot-toast";
import MoveDownIcon from '@mui/icons-material/MoveDown';

export interface ArtifactTransferResult {
    success: boolean;
    result?: {batchId: number}
    errors?: string[]
}

export interface ArtifactTransferModalDatasources {
    artifactLocations: LookupRow[];
    curatorDispositions: LookupRow[];
    buildings: BuildingModel[];
    safes: SafeModel[];
    shelfs: ShelfModel[];
    bins: BinModel[];
}
export interface ArtifactTransferModalFields {
    artifactIds: number[];
    transferType: ArtifactTransferType;
    artifactLocationId?: number;
    curatorDispositionId?: number;
    buildingId?: number;
    safeId?: number;
    shelfId?: number;
    binId?: number;
}

interface ArtifactTransferModalProps {
    datasources: ArtifactTransferModalDatasources;
    artifacts: ArtifactModel[];
    onClose?: () => void;
    open?: boolean;
    onSubmit: (fields: ArtifactTransferModalFields) => Promise<ArtifactTransferResult>;
    onReset?: (fields: ArtifactTransferModalFields) => void;
}


export const ArtifactTransferModal: FC<ArtifactTransferModalProps> = (props) => {
    const { onSubmit, onReset, onClose, datasources, artifacts, open = false } = props;
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [buildings] = useState<FormSelectItem2<number>[]>(toFormSelectItems2(datasources.buildings, FormSelectItemLabel2.None));
    const [safes, setSafes] = useState<FormSelectItem2<number>[]>(toFormSelectItems2([], FormSelectItemLabel2.None));
    const [safesDisabled, setSafesDisabled] = useState<boolean>(true);
    const [shelfs, setShelfs] = useState<FormSelectItem2<number>[]>(toFormSelectItems2([], FormSelectItemLabel2.None));
    const [shelfsDisabled, setShelfsDisabled] = useState<boolean>(true);
    const [bins, setBins] = useState<FormSelectItem2<number>[]>(toFormSelectItems2([], FormSelectItemLabel2.None));
    const [binsDisabled, setBinsDisabled] = useState<boolean>(true);
    const [buildingId, setBuildingId] = useState<number>(-1);
    const [safeId, setSafeId] = useState<number>(-1);
    const [shelfId, setShelfId] = useState<number>(-1);
    const [binId, setBinId] = useState<number>(-1);
    const [artifactLocationId, setArtifactLocationId] = useState<number>(-1);
    const [curatorDispositionId, setCuratorDispositionId] = useState<number>(-1);

    const [transferType, setTransferType] = useState<number>(ArtifactTransferType.LegacyLocation.valueOf());

    const [fields] = useState({
        transferType: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("transferType"),
            "Transfer Type"),
        buildingId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("buildingId"),
            "Building"),
        safeId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("safeId"),
            "Safe"),
        shelfId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("shelfId"),
            "Shelf"),
        binId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("binId"),
            "Bin"),
        artifactLocationId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("artifactLocationId"),
            "Location"),
        curatorDispositionId: new FormFieldDefinition(
            nameof<ArtifactTransferModalFields>("curatorDispositionId"),
            "Curator Disposition"),
    });


    const setSelectedBuilding = (buildingId: number) => {

        setBuildingId(buildingId);

        const filteredSafes = datasources.safes
            .filter(f => f.buildingId === buildingId);

        setSafes(toFormSelectItems2(filteredSafes, FormSelectItemLabel2.None));
        setSafesDisabled(buildingId === -1);
        setSafeId(-1)

        setShelfs(toFormSelectItems2([], FormSelectItemLabel2.None));
        setShelfsDisabled(true);
        setShelfId(-1)

        setBins(toFormSelectItems2([], FormSelectItemLabel2.None));
        setBinsDisabled(true);
        setBinId(-1)

    };

    const setSelectedSafe = (safeId: number) => {
        setSafeId(safeId);

        const filteredShelfs = datasources.shelfs
            .filter(f => f.safeId === safeId);

        setShelfs(toFormSelectItems2(filteredShelfs, FormSelectItemLabel2.None));
        setShelfsDisabled(safeId === -1);
        setShelfId(-1)

        setBins(toFormSelectItems2([], FormSelectItemLabel2.None));
        setBinsDisabled(true);
        setBinId(-1)
    };

    const setSelectedShelf = (shelfId: number) => {
        setShelfId(shelfId);

        const filteredBins = datasources.bins
            .filter(f => f.shelfId === shelfId);

        setBins(toFormSelectItems2(filteredBins, FormSelectItemLabel2.None));
        setBinsDisabled(shelfId === -1);
        setBinId(-1);
    };

    const resetForm = () => {
        setTransferType(ArtifactTransferType.LegacyLocation);
        setSelectedBuilding(-1);
        setSelectedSafe(-1);
        setSelectedShelf(-1);
        setBinId(-1);
        setArtifactLocationId(-1);
        setCuratorDispositionId(-1);
        setErrors([]);
    };

    const undefinedIfDefault = (value: any) => {
        if(isNaN(value)) {
            return undefined;
        }else {
            return value === -1 ? undefined : value;
        }
    }

    const getFormFields = () : ArtifactTransferModalFields => {
        const fields : ArtifactTransferModalFields = {
            artifactIds: artifacts.map(m => m.id),
            transferType,
            artifactLocationId: undefinedIfDefault(artifactLocationId),
            curatorDispositionId: undefinedIfDefault(curatorDispositionId),
            buildingId: undefinedIfDefault(buildingId),
            safeId: undefinedIfDefault(safeId),
            shelfId: undefinedIfDefault(shelfId),
            binId: undefinedIfDefault(binId),
        }
        return fields;
    };
    const handleFieldValueChanged = (field: string, value: any | null) => {

        switch (field) {
            case fields.transferType.name:
                setTransferType(+value);
                break;
            case fields.buildingId.name:
                setSelectedBuilding(value);
                break;
            case fields.safeId.name:
                setSelectedSafe(value);
                break;
            case fields.shelfId.name:
                setSelectedShelf(value);
                break;
            case fields.binId.name:
                setBinId(value);
                break;
            case fields.artifactLocationId.name:
                setArtifactLocationId(value);
                break;
            case fields.curatorDispositionId.name:
                setCuratorDispositionId(value);
                break;
            default:
                break;
        }
    };

    const handleFormReset = () => {
        resetForm();
        if(onReset) {
            const fields = getFormFields();
            onReset?.(fields);
        }
    };

    const handleFormSubmit = async (): Promise<void> => {

        setIsSubmitting(true);

        const fields = getFormFields();
        const result = await onSubmit(fields);
        setIsSubmitting(false);
        if (result.success) {
            toast.success(`Transfer Processed. Batch ${result.result?.batchId}`)
            resetForm();
            onClose?.();
        } else if (result.errors) {
            setErrors(result.errors);
        }
    };


    const handleDialogClose = (): void => {
            resetForm();
            onClose?.();
    };



    return (

        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            scroll='body'
            // onClose={handleDialogClose}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: 100 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={3}
                sx={{
                    px: 3,
                    pt: 2,
                    pb: 0
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">

                    <Typography variant="h5">
                        Transfer Artifacts
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={handleDialogClose}
                        disabled={isSubmitting}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>

            </Stack>

            <DialogContent>
                <UIMessage
                    type="error"
                    messages={errors}
                    onClose={() => setErrors([])} />

                <Stack spacing={2}>
                    <Typography variant="h6">
                    Transfer Batch ({artifacts.length})
                    </Typography>

                    <ArtifactTags artifacts={artifacts} />

                    <Typography variant="h6">
                        Transfer Type
                    </Typography>

                    <FormRadioGroup
                        name={fields.transferType.name}
                        label={undefined}
                        value={transferType}
                        datasource={artifactTransferTypes}
                        onChange={handleFieldValueChanged}
                        horizontal={true}
                        disabled={isSubmitting} />
                    <Typography variant="h6">
                        Transfer Destination
                    </Typography>

                    {transferType === ArtifactTransferType.InventoryLocation ? (
                        <>
                            <FormSelect2
                                {...fields.buildingId}
                                value={buildingId}
                                datasource={buildings}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting} />

                            <FormSelect2
                                {...fields.safeId}
                                value={safeId}
                                datasource={safes}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting || safesDisabled} />

                            <FormSelect2
                                {...fields.shelfId}
                                value={shelfId}
                                datasource={shelfs}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting || shelfsDisabled} />

                            <FormSelect2
                                {...fields.binId}
                                value={binId}
                                datasource={bins}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting || binsDisabled} />
                        </>
                    ) : (
                        <>
                            <FormSelect2
                                {...fields.artifactLocationId}
                                value={artifactLocationId}
                                datasource={toFormSelectItems2(datasources.artifactLocations, FormSelectItemLabel2.None)}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting} />
                            <FormSelect2
                                {...fields.curatorDispositionId}
                                value={curatorDispositionId}
                                datasource={toFormSelectItems2(datasources.curatorDispositions, FormSelectItemLabel2.None)}
                                onChange={handleFieldValueChanged}
                                disabled={isSubmitting}
                            />
                        </>
                    )}



                </Stack>

                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="flex-end"
                    sx={{ pt: 3 }}>
                    <IconButton
                        sx={{ mr: 2 }}
                        onClick={handleFormReset}
                    >
                        <SvgIcon>
                            <RestartAltOutlinedIcon />
                        </SvgIcon>
                    </IconButton>
                    <Button
                        startIcon={(
                            <SvgIcon>
                                  <MoveDownIcon />
                            </SvgIcon>
                        )}
                        variant="contained"
                        onClick={handleFormSubmit}
                    >
                        Complete Transfer
                    </Button>
                </Stack>

            </DialogContent>
        </Dialog>

    );
};

