import { FormControl, MenuItem, TextField } from "@mui/material";
import { ChangeEvent } from "react";


export interface FormSelectItem2<T> {
    label: string;
    value: T;
}

interface FormSelectProps2 {
    label: string;
    name: string;
    value?: any | null;
    datasource: any[];
    onChange: (key: string, value: any | null) => void;
    disabled?: boolean;
}


export enum FormSelectItemLabel2
{
    All,
    None
}

export const toFormSelectItems2 = (rows: { name: string, id: number }[], labelType: FormSelectItemLabel2): FormSelectItem2<number>[] => {
    const items = rows.map<FormSelectItem2<number>>(r => ({ label: r.name, value: r.id })) || [];
    items.unshift({ value: -1, label: labelType === FormSelectItemLabel2.All ? "All" : "< None >" })
    return items;
}

export const FormSelect2 = ({ label, name, value, datasource, onChange, disabled = false }: FormSelectProps2) => {

    const handleFieldChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        changeFieldValue(value);
    }

    const changeFieldValue = (value: any) => {
        onChange(name, value);
    }

    return (
        <FormControl >
            <TextField
                select
                name={name}
                value={value}
                onChange={handleFieldChanged}
                label={label}
                disabled={disabled}
            >
                {datasource.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    )
};


