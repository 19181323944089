import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent } from "react";


export interface FormRadioGroupItem {
    label: string;
    value: any;
}

export interface FormRadioGroupProps {
    label?: string;
    name: string;
    value?: any | null;
    datasource: FormRadioGroupItem[];
    onChange: (key: string, value: any | null) => void;
    horizontal?: boolean;
    disabled?: boolean;
}


export const FormRadioGroup = ({ label, name, value, datasource, onChange, horizontal=false, disabled = false }: FormRadioGroupProps) => {

    const handleFieldChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(name, value);
    }

    return (
        <FormControl>
            {label && (
                 <FormLabel>{label}</FormLabel>
            )}

            <RadioGroup
                name={name}
                value={value}
                key={value}
                onChange={handleFieldChanged}
                row={horizontal}
            >

                {datasource.map(({ value, label }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={disabled}/>
                ))}

            </RadioGroup>
        </FormControl>
    )
};


